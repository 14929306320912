import { IconButton, Input, InputGroup, InputLeftElement, Stack, Text } from '@chakra-ui/react';
import { useAuth } from 'oidc-react';
import theme from '../theme';
import { CSSProperties } from 'react';
import { Search } from 'react-feather';
import DocumentCard from '../components/DocumentCard';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';

function Header() {
  const auth = useAuth();
  return (
    <div style={{ height: '247px', width: '100%', marginTop: '97px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: '10px', background: 'linear-gradient(0deg, #9F7AEA 0.51%, #E4DAF9 54%, #FFFFFF 97%)' }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <Text fontWeight={700} color={theme.colors.purple[500]} fontSize="xxx-large">
          {auth.userData?.profile.given_name}'
        </Text>
        {!auth.userData?.profile.given_name?.endsWith('s') && !auth.userData?.profile.given_name?.endsWith('z') ? (
          <Text fontWeight={700} color={theme.colors.purple[500]} fontSize="xxx-large">
            s
          </Text>
        ) : null}{' '}
        <Text fontWeight={700} fontSize="xxx-large">
          &nbsp;research page
        </Text>
      </div>
      {/* <Searchbar style={{ marginTop: '20px' }} /> */}
    </div>
  );
}

function Searchbar(props: { style?: CSSProperties }) {
  return (
    <div style={{ ...props.style }}>
      <InputGroup>
        <InputLeftElement>
          <Search />
        </InputLeftElement>
        <Input placeholder="Do a quick search for a saved case..." width={'536px'} backgroundColor={'white'} />
      </InputGroup>
    </div>
  );
}

function PreviouslyViewedCases() {
  const doc_ids = ['hudoc:001-73198', 'hudoc:001-123422', 'hudoc:001-57580'];
  return (
    <div style={{ height: '100%', display: 'flex', margin: '20px', flexDirection: 'column', backgroundColor: theme.colors.gray[100], borderRadius: '10px', padding: '10px', overflowY: 'auto' }}>
      <Text fontWeight={600} color={theme.colors.purple[700]} fontSize="large" marginLeft={'50px'} marginTop={'30px'}>
        Recommended Cases
      </Text>
      <div style={{ height: 'fill-available', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', overflowY: 'auto', overflowX: 'hidden' }}>
        {/* <IconButton icon={<ArrowLeftIcon />} aria-label="Left" /> */}
        <Stack height={"100%"} direction="row" wrap={'wrap'} spacing={4} padding={'10px'}>
          {doc_ids.map((doc_id) => (
            <DocumentCard key={doc_id} id={doc_id} />
          ))}
        </Stack>
        {/* <IconButton icon={<ArrowRightIcon />} aria-label="Right" /> */}
      </div>
    </div>
  );
}

export default function ResearchPage() {
  return (
    <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <Header />
      <PreviouslyViewedCases />
    </div>
  );
}
