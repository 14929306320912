import { Button, Card, CardBody, CardFooter, HStack, Heading, Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, Spinner, Stack, Tag, Text, Tooltip, theme, useToast } from '@chakra-ui/react';
import { useGetNodeQuery } from '../lib/api/GraphAPI';
import { useAppDispatch } from '../lib/hooks';
import { motion } from 'framer-motion';
import { ImportanceNames } from '../lib/api/APITypes';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { getDefaultGraphView } from '../lib/GraphFactory';
import { importance_level_tooltips } from '../strings/tooltips';
import { addNewGraphView, openDocument } from '../lib/state/slices/ViewSlice';
import { CSSProperties, useState } from 'react';
import { ChevronDown, Maximize2, X } from 'react-feather';

export default function DocumentCard(props: { id: string; nodeId?: string; collapsedState?: 'open' | 'halfOpen' | 'collapsed'; onClose?: () => void, style?: CSSProperties }) {
  const [collapsedState, setCollapsedState] = useState<'open' | 'halfOpen' | 'collapsed'>('collapsed');
  const { data: nodeData, error: nodeError, isLoading: nodeIsLoading, isSuccess: nodeIsSuccess } = useGetNodeQuery(props.id);
  const toast = useToast();

  const dispatch = useAppDispatch();

  function openModal() {
    dispatch(openDocument(props.id));
  }

  function openInGraph() {
    let newGraphView = getDefaultGraphView(props.id);
    newGraphView.name = nodeData ? nodeData.properties.label!!.replace("CASE OF", '') : "Graph " + props.id;
    dispatch(addNewGraphView(newGraphView));
  }
  const textVariants = {
    halfOpen: {
      display: 'block',
      overflowY: 'hidden',
      maxHeight: '150px'
    },
    open: {
      display: 'block',
      overflowY: 'auto',
      maxHeight: '200px'
    },
    collapsed: {
      display: 'none'
    }
  }
  return (
    <motion.div initial={{ opacity: 0, height: '0', x: -100 }} animate={{ opacity: 1, height: 'auto', x: 0 }} style={{ ...props.style }}>
      <Card maxWidth={'400px'} minWidth={'350px'} zIndex={10} style={{ boxShadow: '0px 4px 20px -4px #00000080' }}>
        <CardBody>
          <Stack spacing="3">
            <Stack direction={'row'} justifyContent={'space-between'}>
              {nodeIsLoading ? <Spinner /> : <Heading size="md" whiteSpace={'normal'}>{nodeData ? nodeData.properties.label : 'Something went wrong'}</Heading>}
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Icon
                  onClick={() => {
                    if (collapsedState === 'open' || collapsedState === 'halfOpen') setCollapsedState('collapsed');
                    else if (collapsedState === 'collapsed') setCollapsedState('halfOpen');
                  }}
                  cursor={'pointer'}
                  boxSize={5}
                  as={Maximize2}
                  padding={'2px'}
                  color={theme.colors.purple[700]}
                />
                {props.onClose ? <Icon onClick={props.onClose} cursor={'pointer'} boxSize={5} as={X} color={theme.colors.red[800]} marginLeft={'10px'} /> : null}
              </div>
            </Stack>
            <HStack spacing={1} flexWrap={'wrap'}>
              {nodeData?.properties.importance ? (
                <Tooltip label={importance_level_tooltips[nodeData?.properties.importance]}>
                  <Tag variant={'solid'} bgColor={theme.colors.blue[900]}>
                    {ImportanceNames[nodeData?.properties.importance]}
                  </Tag>
                </Tooltip>
              ) : null}
              {nodeData?.properties.violation
                ? nodeData?.properties.violation.split(';').map((violation) => (
                  <Tag bgColor={theme.colors.red[600]} textColor={'white'}>
                    Article {violation}
                  </Tag>
                ))
                : null}
              {nodeData?.properties.nonviolation
                ? nodeData?.properties.nonviolation.split(';').map((nonviolation) => (
                  <Tag bgColor={theme.colors.green[600]} textColor={'white'}>
                    Article {nonviolation}
                  </Tag>
                ))
                : null}
              {nodeData?.properties.keywords
                ? nodeData?.properties.keywords.map((keyword) => (
                  <>
                    <Tooltip label={keyword}>
                      <Tag bgColor={theme.colors.yellow[500]} textColor={'white'}>
                        {keyword.length > 10 ? keyword.substring(0, 10) + '...' : keyword}{' '}
                      </Tag>
                    </Tooltip>
                  </>
                ))
                : null}
            </HStack>
            {nodeData ? (
              <HStack spacing={2}>
                <Text fontSize="14px" fontWeight={600} color={theme.colors.gray[700]}>
                  Date: {new Date(nodeData.properties.judgementdate!).toLocaleDateString('de-DE')}{' '}
                </Text>
                <Text fontSize="14px" fontWeight={600} color={theme.colors.gray[700]}>
                  |
                </Text>
                <Text fontSize="14px" fontWeight={600} color={theme.colors.gray[700]}>
                  Appno:{' '}
                  {
                    <>
                      <Tooltip label={nodeData.properties.appno.replaceAll(';', '  ')}>{nodeData.properties.appno.length > 10 ? nodeData.properties.appno.substring(0, 10) + '...' : nodeData.properties.appno}</Tooltip>
                    </>
                  }
                </Text>
              </HStack>
            ) : (
              <Spinner />
            )}
            {nodeIsLoading ? (
              <Spinner colorScheme="purple" />
            ) : collapsedState === 'halfOpen' || collapsedState === 'open' ? (
              <div>
                <Text fontWeight={600} color={theme.colors.gray[700]}>
                  Conclusion
                </Text>
                <motion.div variants={textVariants} style={{ position: 'relative', scrollbarWidth: 'thin' }} initial="collapsed" animate={collapsedState}>
                  {collapsedState === 'halfOpen' ? <div style={{ position: 'absolute', bottom: '0px', zIndex: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', textAlign: 'center', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.9)' }} onClick={() => { if (collapsedState == 'open') setCollapsedState('halfOpen'); else if (collapsedState == 'halfOpen') setCollapsedState('open') }}>
                    <Text fontWeight={700} color={theme.colors.purple[700]}>See more</Text>
                    <ChevronDown />
                  </div> : null}
                  <Text fontSize="14px" whiteSpace={'normal'}>
                    {nodeData?.properties.conclusion}  
                  </Text>

                </motion.div>
                {collapsedState === 'open' ? <div style={{ position: 'initial', bottom: '0px', zIndex: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', textAlign: 'center', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.9)' }} onClick={() => { if (collapsedState == 'open') setCollapsedState('halfOpen'); else if (collapsedState == 'halfOpen') setCollapsedState('open') }}>
                  <Text fontWeight={700} color={theme.colors.purple[700]}>See less</Text>
                  <ChevronDown style={{ transform: 'rotate(180deg)' }}/>
                </div> : null}
              </div>
            ) : null}
          </Stack>
        </CardBody>
        {collapsedState === 'halfOpen' || collapsedState === 'open' ? (
          <CardFooter>
            <HStack width="100%" justifyContent={'space-between'}>
              {/* <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                  Open
                </MenuButton>
                <MenuList maxH="200px">
                  <MenuItem onClick={(event) => openModal()}>Open Modal</MenuItem>
                  <MenuItem onClick={(event) => window.open(nodeData?.properties.url, '_blank')?.focus()}>Open in Hudoc</MenuItem>
                </MenuList>
              </Menu> */}
              <Button
                variant="ghost"
                colorScheme="purple"
                onClick={(event) => {
                  window.open(nodeData?.properties.url, '_blank')?.focus()
                }}
              >
                Open in Hudoc
              </Button>
              <Button
                variant="solid"
                colorScheme="purple"
                onClick={(event) => {
                  openInGraph();
                  toast({
                    title: 'Graph opened',
                    description: 'Your Graph has been opened in a new tab',
                    status: 'info',
                    duration: 3000,
                    isClosable: true,
                  });
                }}
              >
                Open Graph
              </Button>
            </HStack>
          </CardFooter>
        ) : null}
      </Card>
    </motion.div>
  );
}
