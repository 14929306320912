import { Button, Menu, MenuButton, MenuItem, MenuList, Stack, useToast } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../../lib/hooks';
import { GraphView } from '../../../lib/state/ViewTypes';
import { getDefaultGraphView } from '../../../lib/GraphFactory';
import { addNewGraphView, removeNodeContextMenu, saveDoc } from '../../../lib/state/slices/ViewSlice';
import { ChevronDownIcon } from '@chakra-ui/icons';

export default function NodeContextMenu(props: { nodeLabel: string; nodeId: string; id: string; graphViewIndex: number; position: { x: number; y: number }; loadNeighbours: (id: string) => void; recommendNeighbours: (id: string, nodeId: string) => void }) {
  const dispatch = useAppDispatch();
  const folders = useAppSelector((state) => state.viewReducer.folders);
  const toast = useToast();
  return (
    <div
      style={{
        position: 'absolute',
        top: props.position.y,
        left: props.position.x,
        backgroundColor: '#f0f0f0f0',
        padding: '3px',
        borderRadius: '3px',
        zIndex: 1000,
      }}
    >
      <Stack>
        <Button
          onClick={() => {
            let newGraphView = getDefaultGraphView(props.id);
            newGraphView.name = props.nodeLabel.replace('CASE OF', '');
            dispatch(removeNodeContextMenu({ graphViewIndex: props.graphViewIndex }));
            dispatch(addNewGraphView(newGraphView));
            toast({
              title: 'Graph opened',
              description: 'Your Graph has been opened in a new tab',
              status: 'info',
              duration: 3000,
              isClosable: true,
            });
          }}
        >
          New Center
        </Button>
        <Button
          onClick={() => {
            props.loadNeighbours(props.id);
            toast({
              title: 'Neigbours loaded',
              description: 'The new neighbours might not be visible due to filters',
              status: 'info',
              duration: 3000,
              isClosable: true,
            });
          }}
        >
          Expand Neighbours
        </Button>
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            Save to Folder
          </MenuButton>
          <MenuList>
            {folders.map((folder) => (
              <MenuItem
                key={folder.id}
                onClick={() => {
                  dispatch(saveDoc({ folderId: folder.id, nodeId: props.id }));
                  toast({
                    title: 'Document saved',
                    description: 'The document has been saved to ' + folder.name,
                    status: 'info',
                    duration: 3000,
                    isClosable: true,
                  });
                }}
              >
                {folder.name}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        {/* <Button
          onClick={() => {
            props.recommendNeighbours(props.id, props.nodeId);
            toast({
              title: 'Neigbours recommended',
              description: 'The new neighbours might not be visible due to filters',
              status: 'info',
              duration: 3000,
              isClosable: true,
            });
          }}
        >
          Recommend Neighbours
        </Button> */}
      </Stack>
    </div>
  );
}
