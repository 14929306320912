import { useEffect, useState } from 'react';
import Graph from 'graphology';
import EdgeArrowProgram from 'sigma/rendering/webgl/programs/edge.arrow';
import { addEdgeToGraph, addNodeToGraph, graphFromData } from '../lib/GraphFactory';
import { ControlsContainer, SearchControl, SigmaContainer, ZoomControl, useLoadGraph, useSigma } from '@react-sigma/core';
import '@react-sigma/core/lib/react-sigma.min.css';
import NodeTooltip from '../components/NodeTooltip';
import FloatingDocument from '../components/FloatingDocument';
import GraphEvents from '../components/Graph/Events';
import { useAppSelector } from '../lib/hooks';
import TimeFilter from '../components/Graph/controls/TimeFilter';
import NodeContextMenu from '../components/Graph/controls/NodeContextMenu';
import { useGetNeighboursQuery, useGetRecommendedNeighboursQuery } from '../lib/api/GraphAPI';
import { store } from '../lib/state/Store';
import Filter from '../components/Graph/controls/Filter';
import QuickFilter from '../components/Graph/controls/QuickFilter';
import Timeline from '../components/Graph/Timeline';
import Toolbar from '../components/Toolbar';
import SearchControlWrapper from '../components/SearchControlWrapper';
import LoadingScreen from './LoadingScreen';
import { Button, IconButton, Text } from '@chakra-ui/react';
import { setToolOpenStatus } from '../lib/state/slices/ViewSlice';
import { SearchIcon } from '@chakra-ui/icons';
import DocumentCard from '../components/DocumentCard';

const Header = (props: { graphName: string }) => {
  const toolOpenStatus = useAppSelector((state) => state.viewReducer.toolOpenStatus);

  return (
    <div style={{ width: '100%', padding: '20px', flexDirection: 'row', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      <Text fontWeight={800} fontSize={'x-large'}>
        {props.graphName}
      </Text>
      <Button marginLeft={'10px'} type="button" colorScheme="purple" onClick={() => store.dispatch(setToolOpenStatus({ ...toolOpenStatus, advancedFilters: !toolOpenStatus.advancedFilters }))}>
        Filter
      </Button>
      {/* <IconButton marginLeft={'10px'} aria-label='Search' icon={<SearchIcon />} type='button' onClick={() => store.dispatch(setToolOpenStatus({...toolOpenStatus, search: !toolOpenStatus.search}))} /> */}
    </div>
  );
};

const LoadNeighbours = (props: { id: string }) => {
  const { data, error, isLoading, isSuccess } = useGetNeighboursQuery({
    id: props.id,
    level: 1,
  });

  const sigma = useSigma();
  const graph = sigma.getGraph();
  useEffect(() => {
    if (data && isSuccess) {
      for (let i = 0; i < data.nodes.length; i++) {
        if (!graph.hasNode(data.nodes[i].elementId)) {
          let node = data.nodes[i];
          addNodeToGraph(node, graph);
        }
      }
      for (let i = 0; i < data.edges.length; i++) {
        if (!graph.hasEdge(data.edges[i].elementId)) {
          let edge = data.edges[i];
          addEdgeToGraph(edge, graph);
        }
      }
    }
  }, [props.id, data]);

  return null;
};
const RecommendNeighbours = (props: { id: string; nodeId: string }) => {
  const { data, error, isLoading, isSuccess } = useGetRecommendedNeighboursQuery(props.id);

  const sigma = useSigma();
  const graph = sigma.getGraph();
  useEffect(() => {
    if (data && isSuccess) {
      for (let i = 0; i < data.nodes.length; i++) {
        if (!graph.hasNode(data.nodes[i].elementId)) {
          let node = data.nodes[i];
          addNodeToGraph(node, graph);
          // Set color based on distance
          graph.addUndirectedEdge(props.nodeId, node.elementId, { distance: data.distances[i], size: 10 * (1 - data.distances[i]) });
        }
      }
    }
  }, [props.id, data]);

  return null;
};

const LoadGraph = (props: { graph: Graph }) => {
  const loadGraph = useLoadGraph();
  const refreshCounter = useAppSelector((state) => state.viewReducer.refreshCounter);

  const sigma = useSigma();
  useEffect(() => {
    loadGraph(props.graph);
    sigma.refresh();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      sigma.refresh();
      sigma.resize();
    }, 300);
    sigma.refresh();
    sigma.resize();
  }, [refreshCounter]);

  return null;
};

export const GraphTabLayout = (props: { graphViewIndex: number; isActive?: boolean }) => {
  const { data, error, isLoading, isSuccess } = useGetNeighboursQuery({
    id: store.getState().viewReducer.graphViews[props.graphViewIndex].centerNode,
    level: 1,
  });
  const [loadNeighbours, setLoadNeighbours] = useState<string>();
  const [recommendNeighbours, setRecommendNeighbours] = useState<{ id: string; nodeId: string }>();
  // const [timelinePosition, setTimelinePosition] = useState<{
  //   x: number; y: number;
  // }>();
  const [hoveredNode, onHover] = useState<{
    event: { x: number; y: number };
    node: string;
  }>();
  const [clickedNode, onClick] = useState<{
    event: { x: number; y: number };
    node: string;
  }>();
  const [floatingDocuments, setFloatingDocuments] = useState<Array<{ nodeId: string; id: string; url: string; coords: { x: number; y: number }; graphViewIndex: number }>>([]);
  const nodeContextMenu = useAppSelector((state) => state.viewReducer.graphViews[props.graphViewIndex].nodeContextMenu);
  const openTools = useAppSelector((state) => state.viewReducer.toolOpenStatus);

  if (isLoading) {
    return <LoadingScreen />;
  }
  if (data && isSuccess) {
    return (
      <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Header graphName={store.getState().viewReducer.graphViews[props.graphViewIndex].name}></Header>
        <style>
          {`
            .sigma-container {
              background-color: #E2E8F0;
              flex-grow: 1;
            }
          `}
        </style>
        <SigmaContainer
          style={{ height: '100%', width: '100%', display: 'flex' }}
          settings={{
            hoverRenderer: () => {},
            defaultEdgeType: 'arrow',
            edgeProgramClasses: { arrow: EdgeArrowProgram },
          }}
        >
          {/* <Toolbar /> */}
          <LoadGraph
            graph={graphFromData({
              data: data,
              centerNode: store.getState().viewReducer.graphViews[props.graphViewIndex].centerNode,
            })}
          />
          <GraphEvents
            onClickNode={onClick}
            onHoverNode={onHover}
            graphViewIndex={props.graphViewIndex}
            isActive={props.isActive}
            floatingDocuments={floatingDocuments}
            setFloatingDocuments={setFloatingDocuments}
            // setTimelinePosition={setTimelinePosition}
          />
          <ControlsContainer position="top-right">
            <SearchControlWrapper>
              <SearchControl />
            </SearchControlWrapper>
          </ControlsContainer>
          {hoveredNode ? <NodeTooltip id={hoveredNode.node} position={hoveredNode.event} /> : <></>}
          {floatingDocuments.map((doc) => (
            <DocumentCard
              key={doc.id}
              id={doc.id}
              style={{ position: 'absolute', zIndex: '10', top: doc.coords.y + 20, left: doc.coords.x - 200 }}
              onClose={() => {
                let floats = [...floatingDocuments];
                const index = floats.findIndex((node) => node.id === doc.id);
                if (index === 0 || index) {
                  floats.splice(index, 1);
                  setFloatingDocuments(floats);
                }
              }}
            />
          ))}
          <TimeFilter style={{ position: 'absolute', bottom: '20px', left: '30%' }} graphViewIndex={props.graphViewIndex} />
          <Filter style={{ position: 'absolute', top: '20px', left: '20px', width: '300px' }} graphViewIndex={props.graphViewIndex} />
          {/* <QuickFilter style={{ position: 'absolute', right: '20px', bottom: '20px' }} graphViewIndex={props.graphViewIndex} /> */}
          {/* <Timeline style={{ position: "absolute", left: timelinePosition?.x, top: timelinePosition?.y }} /> */}
          {nodeContextMenu ? (
            <NodeContextMenu
              nodeLabel={nodeContextMenu.nodeLabel}
              id={nodeContextMenu.id}
              graphViewIndex={props.graphViewIndex}
              nodeId={nodeContextMenu.nodeId}
              position={nodeContextMenu.coords}
              loadNeighbours={(id: string) => {
                setLoadNeighbours(id);
              }}
              recommendNeighbours={(id: string, nodeId: string) => {
                setRecommendNeighbours({ id, nodeId });
              }}
            />
          ) : null}
          {loadNeighbours ? <LoadNeighbours id={loadNeighbours} /> : null}
          {recommendNeighbours ? <RecommendNeighbours id={recommendNeighbours.id} nodeId={recommendNeighbours.nodeId} /> : null}
        </SigmaContainer>
      </div>
    );
  } else {
    return <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>Error loading the Graph</div>;
  }
};
